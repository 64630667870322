.card, .list-group-item  {
    background-color: inherit !important;
}
//Dearborn's color choices for providerdna

$primary:      #005587; //dark-blue
$secondary:    #636569; //dark-grey
$info:         #0080C7; //blue

$btn-hover-bg-shade-amount:       25%;
$btn-hover-bg-tint-amount:        25%;

.content-wrapper {
  min-height: 600px;
  border: 1px inset rgb(230 238 243 / 20%);
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.bg-container {
  z-index: -5;
  position: fixed;
  top: -100px;
  bottom: 0;
  left: 0;
  right: 0;
  transform: skewY(-12deg);
}
.bg-top {
  transform-origin: 0%;
  height: 100%;
  background-color: $info;
  position: absolute;
  top: -85%;
  left: 0;
  right: 0;
  opacity: .05;
}
.bg-bottom {
  height: 100%;
  background-color: $info;
  position: absolute;
  top: 95%;
  left: 0;
  right: 0;
  opacity: .05;
}
$enable-negative-margins: true;
@import "../../node_modules/bootstrap/scss/functions";

$custom-theme-colors: (
    "critical": #8B0000 //dark-red
);

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/utilities/api";

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

$utilities: map-merge (
  $utilities,
  (
    "color": map-merge(
      map-get($utilities, "color"),
      (
        values: map-merge(
          map-get(map-get($utilities, "color"), "values"),
          (
            $custom-theme-colors
          ),
        ),
      ),
    ),
  )
);

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}
