/* You can add global styles to this file, and also import other style files */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

#wrapper {
    margin-right: auto;
    margin-left: auto;
}

#main-wrapper {
    width: 100% !important;
}

.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.instructions {
    border: 0px !important;
}

.icon-sm {
    font-size: 0.8rem;
}

.form-group.required > label:after {

    content:" *";
    font-size: 15px;
    font-weight: bold;
    color:red;
}

.form-floating.required > label:after {

    content:" *";
    font-size: 15px;
    font-weight: bold;
    color:red;

}

.form-check.required > label:after {
  content:" *";
  font-size: 15px;
  font-weight: bold;
  color:red;
}

.required-field::after {
    content: " *";
    font-size: 15px;
    font-weight: bold;
    color: red;
}

.font-weight-700 {
    font-weight: 700 !important;
}

@media all and (min-width: 992px) {
    .pull-left-50{
        margin-left: -50px;
    }
}

.label-margin-10{
    margin-left: 10px;
}

@keyframes fadeOutUp {
    0% {
       opacity: 1;
       transform: translateY(0);
    }
    100% {
       opacity: 0;
       transform: translateY(-20px);
    }
 }
 @keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }

 .fade-top.ng-enter, .fade-top.ng-hide-remove {
    animation: fadeInDown 1s;
  }

  .fade-top.ng-leave {
    animation: fadeOutUp 1s;
  }

  .fade-top.ng-hide-add {
    animation: fadeOutUp 1s;
    display: block !important;
  }

  .link {
    text-decoration: none;
  }

  .link:hover {
    text-decoration: underline;
}


.border-red {
  border: 1px solid #dc3545;
}

.border-red:focus {
  border-color:#dc3545;box-shadow:0 0 0 .25rem rgba(220,53,69,0.25)
}

.ngHide {
    visibility: hidden;
}

.smaller-font {
    font-size: 0.95em;
}

@media (min-width: 992px) {
    .app-border-right {
        border-right: 0px !important;
    }
}

.readOnlyColor{
    background-color: #e9ecef;
}

.disable-click {
    pointer-events: none;
    cursor: default;
  }

.rounded-right-border {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
